<template>
  <div>
    <b-row class="mt-1">
      <b-col
        cols="12"
        md="10"
        class="mb-md-0 mb-2"
      >
        <v-select
          v-model="reportFilters.consumerType"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          placeholder="Consumer Type"
          :options="filterConsumerTypeOptions"
          class="w-100"
          @input="
            option => (filters.consumerType = option ? option.value : null)
          "
        />
      </b-col>
      <b-col
        cols="12"
        md="2"
        class="mb-md-0 mb-2"
      >
        <div class="flex justify-center gap-1">
          <b-dropdown
            v-if="$can('download', 'reports')"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            text="Generate"
            variant="primary"
            :disabled="disableGenerate"
            split
            right
            @click="emitGenerate()"
          >
            <b-dropdown-item
              :disabled="disableGenerate"
              @click="emitPDF()"
            >
              PDF
            </b-dropdown-item>
            <b-dropdown-item
              :disabled="disableGenerate"
              @click="emitExcel()"
            >
              Excel
            </b-dropdown-item>
          </b-dropdown>
          <b-button
            v-else
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            :disabled="disableGenerate"
            @click="emitGenerate()"
          >
            Generate
          </b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BButton,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import _ from 'lodash'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BButton,
    BDropdown,
    BDropdownItem,
    vSelect,
    FeatherIcon,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      STORE_MODULE_NAME: 'app-reports-consumer-list',
      reportFilters: {
        consumerType: null,
      },
      filters: {
        consumerType: null,
      },
      disableGenerate: true,
    }
  },
  computed: {
    filterConsumerTypeOptions() {
      const options = this.$store.state.app.enums
        ? this.$store.state.app.enums.ConsumerType
        : []

      if (_.find(options, { key: 'ALL' }) == undefined) {
        options.unshift({
          key: 'ALL',
          label: 'All',
          value: 'all',
        })
      }

      return options
    },
  },
  watch: {
    waiting() {
      this.canGenerate()
    },
    reportFilters: {
      handler() {
        this.canGenerate()
        this.resetActionData()
      },
      deep: true,
    },
    actionData() {
      if (!this.actionData) {
        this.disableExports = true
      }
    },
  },
  methods: {
    canGenerate() {
      this.disableGenerate = this.reportFilters.consumerType === null
      return this.disableGenerate
    },
    setActionData() {
      const payload = {
        filters: this.filters,
      }
      this.$store.dispatch(`${this.STORE_MODULE_NAME}/setActionData`, payload)
    },
    resetActionData() {
      this.$store.dispatch(`${this.STORE_MODULE_NAME}/resetData`)
    },

    emitGenerate() {
      this.setActionData()
      const payload = {
        filters: this.filters,
        rangeDate: this.rangeDate,
      }
      this.$emit('generate', payload)
    },
    emitPDF() {
      this.setActionData()
      const payload = {
        filters: this.filters,
        rangeDate: this.rangeDate,
      }
      this.$emit('open-pdf', payload)
    },
    emitExcel() {
      this.setActionData()
      const payload = {
        filters: this.filters,
        rangeDate: this.rangeDate,
      }
      this.$emit('download-excel', payload)
    },
  },
}
</script>
