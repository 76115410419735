<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <consumer-list-actions
          @generate="generateReport"
          @open-pdf="openPDF"
          @download-excel="downloadExcel"
        />
      </b-card-body>
    </b-card>
    <div>
      <report-title :card="titleCard" />
      <consumer-list-table ref="refTableCard" />
      <busy-modal :card="busyCard" />
    </div>
  </div>
</template>

<script>
import ReportTitle from '@leecom/report-title/ReportTitle.vue'
import { ref, onUnmounted, computed } from '@vue/composition-api'
import store from '@/store'
import {
  BTabs, BTab, BCard, BCardBody, BCardHeader,
} from 'bootstrap-vue'
import BusyModal from '../../../@leecom/busy-modal/BusyModal.vue'
import ConsumerListTable from './table/ConsumerListTable.vue'
import ConsumerListStoreModule from './ConsumerListStoreModule'
import ConsumerListActions from './ConsumerListActions.vue'
import useFetchData from './useFetchData'

export default {
  components: {
    BCard,
    BCardBody,
    BTabs,
    BTab,
    BCardHeader,

    ConsumerListActions,
    ConsumerListTable,
    ReportTitle,
    BusyModal,
  },
  setup() {
    const STORE_MODULE_NAME = 'app-reports-consumer-list'
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, ConsumerListStoreModule, {
        preserveState: false,
      })
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    // reset state when loading page
    store.dispatch(`${STORE_MODULE_NAME}/resetData`)

    const {
      generateReport,
      openPDF,
      downloadExcel,

      titleCard,
      busyCard,

      refTableCard,
    } = useFetchData()

    return {
      generateReport,
      openPDF,
      downloadExcel,

      titleCard,
      busyCard,

      refTableCard,
    }
  },
}
</script>
